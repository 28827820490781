import { useState } from "react";

export function useModal() {
	const [isOpen, setOpen] = useState(false);
	const close = () => setOpen(false);
	const open = () => setOpen(true);

	return [ isOpen, open, close ] as
		[boolean, () => void, () => void];
}
