import React from "react";
import classes from "./style.module.scss";

// MARK: Next
import Image from "next/image";
import Link from "next/link";

// MARK: Components
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";
import LinkButton from "../LinkButton";
import SideDrawer from "../SideDrawer";

// Hooks
import { useModal } from "../../hooks/useModal";

// MARK: Implementation
interface IProps {
	backgroundStyle?: string;
	navBarIsDark?: boolean;
	backButton?: {
		text: string;
		href: string;
		as: string | null;
	};

}

const MainLayout: React.FC<IProps> = (props) => {
	const {
		backgroundStyle,
		backButton,
		navBarIsDark,
	} = props;
	const [isDrawerOpen, openDrawer, closeDrawer] = useModal();

	return (
		<main
			className={`${classes.container} ${backgroundStyle || ""}`}
		>
			<Navbar
				openSidebar={openDrawer}
				isDark={navBarIsDark}
			/>
			{backButton && (
				<div className={classes.fieldButton}>
					<LinkButton
						className={classes.backButton}
						text={backButton.text}
						internal={backButton}
					/>
				</div>
			)}
			{props.children}
			<Footer />
			<SideDrawer
				visible={isDrawerOpen}
				close={closeDrawer}
				open={openDrawer}
			/>
		</main>
	);
};

export default MainLayout;
