// MARK: React
import React from "react";

// MARK: Next
import Image from "next/image";

// MARK: Styles
import classes from "./style.module.scss";

export const SocialMedia: React.FC = () => {
	return (
		<div className={classes.socialMediaContainer}>
			<a
				href="https://www.instagram.com/startapp.one/?hl=pt-br"
				target="blank"
				className={classes.linkContainer}
			>
				<div className={classes.socialMediaRadius}>
					<Image
						layout="fixed"
						width={20}
						height={20}
						alt="instagram_company"
						src="/instagram.png"
					/>
				</div>
			</a>
			<a
				href="https://www.instagram.com/startapp.one/?hl=pt-br"
				target="blank"
				className={classes.linkContainer}
			>
				<div className={classes.socialMediaRadius}>
					<Image
						layout="fixed"
						width={20}
						height={20}
						alt="facebook_company"
						src="/facebook.png"
					/>
				</div>
			</a>
			<a
				href="https://br.linkedin.com/company/startapp-one"
				target="blank"
				className={classes.linkContainer}
			>
				<div className={classes.socialMediaRadius}>
					<Image
						layout="fixed"
						width={20}
						height={20}
						alt="linkedin_company"
						src="/linkedin.png"
					/>
				</div>
			</a>
		</div>
	);
};
