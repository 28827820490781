// MARK: React
import React from "react";

// MARK: Next
import Image from "next/image";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import { useRouter } from "next/router";

// MARK: Components
import { SocialMedia } from "./SocialMedia";
import LinkButton from "../../LinkButton";

// MARK: Resource
import navigationLinks from "../../../resources/navigationLinks";
import Strings from "../../../resources/Strings";

// MARK: Implementation
export const Footer: React.FC = () => {

	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });

	return (
		<div className={classes.centerer}>
			<div className={classes.container}>
				<address className={classes.columnAddress}>
					<a href="https://startapp.one/" className={classes.startappLink}>
						Startapp One
					</a>
					<div className={classes.addressesContainer}>
						{strings.components.footer.titles.addresses.map((address) => (
							<div key={address.addressName} className={classes.containerTitles}>
								<h2 className={classes.titleNegrito}>
									{address.addressName}
								</h2>
								<span>{address.adressStep}</span>
								<span>{address.adressStep2}</span>
							</div>
						))}
					</div>
					<div className={classes.socialMediaNone}>
						<SocialMedia />
					</div>
				</address>
				<div className={classes.socialMediaCenter}>
					<SocialMedia />
				</div>
				<div className={classes.columnLogo}>
					<Image
						width={250}
						height={315}
						alt="BottomGirlImage"
						src="/BottomGirlImage.png"
						className={classes.logoBottomGirl}
					/>
				</div>
				<div className={classes.columnButtons}>
					<div className={classes.containerButtons}>
						{navigationLinks(strings).map((link) => (
							<LinkButton
								key={`${link.text}-footer`}
								className={classes.linkButtonFooter}
								text={link.text}
								internal={link.link}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
