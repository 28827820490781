// MARK: Resources
import { StringsType } from "./Strings";
import Routes, { ILink } from "./Routes";

interface INavigationLinks {
	text: string;
	link: ILink;
}

const navigationLinks = (strings: StringsType): INavigationLinks[] => {

	let currentAvailableLinks = [
		{
			text: strings.pages.home.welComeSection.home,
			link: Routes.index(strings.getLanguage()),
		},
		{
			text: strings.pages.home.welComeSection.consulting,
			link: Routes.consulting(strings.getLanguage()),
		},
		{
			text: strings.pages.home.welComeSection.cases,
			link: Routes.cases(strings.getLanguage()),
		},
		{
			text: strings.pages.faq.faqPage.titleAcronym,
			link: Routes.faq(strings.getLanguage()),
		},
	];

	if (strings.getLanguage() === "pt-BR") {
		currentAvailableLinks = [
			...currentAvailableLinks,
			{
				text: strings.pages.home.welComeSection.blog,
				link: Routes.blog.index(strings.getLanguage()),
			},
		];
	}

	return currentAvailableLinks;

};

export default navigationLinks;
