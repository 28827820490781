// MARK: React
import * as React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import Image from "next/image";
import { useRouter } from "next/router";
import Link from "next/link";

// MARK: Components
import LinkButton from "../../LinkButton";
import MenuButton from "../../MenuButton";
import LanguageOptions from "../../../components/LanguageOptions";
import LinkChildren from "../../LinkChildren";

// MARK: Resources
import Strings from "../../../resources/Strings";
import navigationLinks from "../../../resources/navigationLinks";
import Routes from "../../../resources/Routes";

interface IProps {
	openSidebar: () => void;
	isDark?: boolean;
}

export const Navbar = (props: IProps) => {

	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });
	const { openSidebar, isDark } = props;

	return (
		<div className={`${classes.container} ${isDark && classes.isDark}`}>
			<div className={classes.menuRow}>
				<LinkChildren
					className={classes.imageContainer}
					internal={Routes.index(router.locale)}
				>
					<Image
						className={classes.logo}
						width={185}
						height={70}
						src="/startappLogo.png"
						alt="pt-BR"
					/>
				</LinkChildren>
				<div className={classes.buttonRow}>
					{navigationLinks(strings).map((links) => (
						<LinkButton
							key={links.text}
							className={classes.linkButton}
							text={links.text}
							internal={links.link}
						/>
					))}
					<LanguageOptions />
				</div>
				<div className={classes.menuButton}>
					<LanguageOptions />
					<MenuButton onClick={openSidebar} />
				</div>
			</div>
		</div>
	);
};
