// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import Link from "next/link";

// MARK: Implementation
interface IProps {
	className?: React.HTMLProps<HTMLButtonElement>["className"];
	text: string;
	internal?: {
		href: string;
		as: string;
		locale?: string;
	};
	externalHref?: string;
}

const LinkButton: React.FC<IProps> = (props) => {
	if (props.externalHref) {
		return (
			<a
				href={props.externalHref}
				target="_blank"
				role="button"
				rel="noreferrer"
				className={`${props.className} ${classes.container}`}
			>
				{props.text}
			</a>
		);
	}

	if (props.internal) {
		return (
			<Link
				href={props.internal.href}
				as={props.internal.as}
				locale={props.internal.locale}
			>
				<a
					role="button"
					rel="noreferrer"
					className={`${props.className} ${classes.container}`}
				>
					{props.text}
				</a>
			</Link>
		);
	}

	throw new Error("Internal or externalHref link should be provided for LinkButton");
};

export default LinkButton;
