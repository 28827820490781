// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Next
import Link from "next/link";

// MARK: Implementation
export interface IProps {
	className?: React.HTMLProps<HTMLButtonElement>["className"];
	target?: React.HTMLProps<HTMLButtonElement>["target"];
	internal?: {
		href: string;
		as: string;
		locale?: string;
	};
	externalHref?: string;
}

const LinkChildren: React.FC<IProps> = (props) => {
	if (props.externalHref) {
		return (
			<a
				rel="noreferrer"
				className={props.className ? props.className : classes.container}
				target={props.target || "_self"}
				href={props.externalHref}
			>
				{props.children}
			</a>
		);
	}

	if (props.internal) {
		return (
			<Link
				href={props.internal.href}
				as={props.internal.as}
				locale={props.internal.locale}
			>
				<a
					rel="noreferrer"
					className={props.className ? props.className : classes.container}
					target={props.target || "_self"}
				>
					{props.children}
				</a>
			</Link>
		);
	}

	throw new Error("Internal or externalHref link should be provided for LinkChildren");
};

export default LinkChildren;
