// MARK: React
import * as React from "react";

// MARK: Styles
import classes from "./style.module.scss";

export interface IProps {
	onClick?: (() => void);
	cross?: boolean;
}

const MenuButton: React.FC<IProps> = (props) => {
		return (
			<div
				className={`${classes.menuButton} ${props.cross ? classes.closedMenuButton : "" }`}
				onClick={props.onClick}
				role="button"
				aria-label="Menu"
			>
				<div />
				<div className="bigger"/>
			</div>
		);

};

export default MenuButton;
