// MARK: React
import * as React from "react";

// MARK: Next
import Image from "next/image";
import { useRouter } from "next/router";

// MARK: Style
import classes from "./style.module.scss";

// MARK: Components
import DrawerButton from "./DrawerButton";
import LinkButton from "../../startappComponents/LinkButton";

// MARK: Resources
import Strings from "../../resources/Strings";
import navigationLinks from "../../resources/navigationLinks";

interface IProps extends ISideDrawerProps { }

interface ISideDrawerProps {
	close: () => void;
	open: () => void;
	visible: boolean;
}
const SideDrawer: React.FC<IProps> = (props) => {

	const router = useRouter();
	const strings = Strings({ acceptLanguage: router.locale });

	return (
		<>
			<nav className={`${classes.sideDrawer} ${props.visible ? classes.open : ""} `}>
				<div className={classes.content}>
					<Image
						width={20}
						height={30}
						className={classes.logo}
						src="/StartappIcon.png"
						alt="logo_company"
					/>
					{navigationLinks(strings).map((link) => (
						<LinkButton
							key={`${link.text}-side`}
							className={classes.drawerButton}
							text={link.text}
							internal={link.link}
						/>
					))}
					<DrawerButton
						close={props.close}
						label={strings.general.close}
						href=""
					/>
				</div>
			</nav>
			<div
				role="button"
				className={`${props.open ? classes.open : ""} `}
				aria-label={strings.general.sideDrawer}
				onClick={() => props.close()}
			/>
		</>
	);
};

export default SideDrawer;
