// MARK: React
import React from "react";

// MARK: Styles
import classes from "./style.module.scss";

// MARK: Resources
import Routes from "../../resources/Routes";

// MARK: Components
import LinkChildren from "../../startappComponents/LinkChildren";

// MARK: Next
import Image from "next/image";

// MARK: Implementation
const LanguageOptions: React.FC = () => {
	return (
		<div className={classes.container}>
			<LinkChildren
				internal={Routes.index("pt-BR")}
				className={classes.language}
			>
				<Image
					src="/brazil_flag.png"
					alt="pt-BR"
					width={28}
					height={28}
				/>
			</LinkChildren>
			<LinkChildren
				internal={Routes.index("en-US")}
				className={classes.language}
			>
				<Image
					width={28}
					height={28}
					src="/us_flag.png"
					alt="en-US"
				/>
			</LinkChildren>
		</div>
	);
};

export default LanguageOptions;
