// MARK: Implementation
export interface ILink {
	href: string;
	as: string;
	locale?: string;
}

interface IRouteType {
	[key: string]: ((...x: any) => ILink) | ILink | IRouteType;
}

function getRoutes<T extends IRouteType>(value: T): T {
	return value;
}

export const Helpers = {
};

const Routes = getRoutes({
	index: (locale: string, params?: string | null) => ({
		href: "/[locale]",
		as: `/${locale}${params || ""}`,
		locale,
	}),
	services: (locale: string, params?: string | null) => ({
		href: "/[locale]/services",
		as: `/${locale}/services${params || ""}`,
		locale,
	}),
	cases: (locale: string, params?: string | null) => ({
		href: "/[locale]/cases",
		as: `/${locale}/cases${params || ""}`,
		locale,
	}),
	faq: (locale: string, params?: string | null) => ({
		href: "/[locale]/faq",
		as: `/${locale}/faq${params || ""}`,
		locale,
	}),
	consulting: (locale: string, params?: string | null) => ({
		href: "/[locale]/consulting",
		as: `/${locale}/consulting${params || ""}`,
		locale,
	}),
	talkToUs: () => ({ href: "#contact", as: `` }),
	methodology: () => ({ href: "#methodology", as: `` }),
	contact: () => ({ href: "#contact", as: `` }),
	blog: {
		index: (locale: string, params?: string | null) => ({
			href: "/[locale]/blog",
			as: `/${locale}/blog${params || ""}`,
			locale,
		}),
		post: (locale: string, params?: string | null) => ({
			href: "/[slug]´",
			as: `/${locale}/blog/${params || ""}`,
		}),
		author: (locale: string, params?: string | null) => ({
			href: "/[locale]/blog/author/[slug]",
			as: `/${locale}/blog/author/${params || ""}`,
			locale,
		}),
		tag: (locale: string, params?: string | null) => ({
			href: "/[locale]/blog/tags/[slug]",
			as: `/${locale}/blog/tags/${params || ""}`,
			locale,
		}),
	},
});

export default Routes;
