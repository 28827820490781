// MARK: React
import * as React from "react";

// MARK: Styles
import classes from "./style.module.scss";

interface IProps {
	label: string;
	href: string;
	close: () => void;
}

const DrawerButton: React.FC<IProps> = (props) => {
		return (
			<div
				role="button"
				className={classes.drawerButton}
				onClick={props.close}
			>
				{props.label}
			</div>
		);
};
export default DrawerButton;
